import { createModel } from "@rematch/core";
import * as Request from "../queries/customer";
import { GenerateError } from "../utils/errors";

export const CustomerModel = () =>
    createModel({
        state: {
            ongoing: false,
            error: null,
            offices: [],
            services: [],
            officeServices: [],
            currentCustomer: {},
            logoUrls: [],
        },
        reducers: {
            errors(state, err) {
                return {...state, error: err}
            },
            updateOngoing(state, ok) {
                return {...state, ongoing: ok}
            },
            updateOffices(state, list) {
                return {...state, offices: list}
            },
            updateOfficeServices(state, list) {
                return {...state, officeServices: list}
            },
            updateServices(state, list) {
                return {...state, services: list}
            },
            setCurrentCustomer(state, c) {
                return {...state, currentCustomer: c}
            },
            updateLogos(state, logos) {
                return {...state, logoUrls: logos}
            }
        },

        effects: dispatch => ({
            async CreateAccount(payload) {
                this.updateOngoing(true);
                try {
                    await Request.Create(payload);
                    this.updateOngoing(false);
                    this.errors(setError(null));
                } catch (err) {
                    this.updateOngoing(false);
                    this.errors(GenerateError(err));
                }
            },
            async UpdateAccount(payload) {
                this.updateOngoing(true);
                try {
                    await Request.Update(payload.formData);
                    this.updateOngoing(false);
                    await this.GetAccount(payload.customerId);
                    dispatch.main.SetSuccess();
                } catch (err) {
                    this.updateOngoing(false);
                    dispatch.main.SetError(err);
                }
            },
            async UpSertOffice(payload) {
                this.updateOngoing(true);
                try {
                    await Request.UpSertOffice(payload);
                    this.updateOngoing(false);
                    dispatch.main.SetSuccess();
                    await this.GetOffices(payload.customerId);
                } catch (err) {
                    this.updateOngoing(false);
                    dispatch.main.SetError(err);
                }
            },
            async BindServiceToOffice(payload) {
                this.updateOngoing(true);
                try {
                    await Request.BindServiceToOffice(payload);
                    this.updateOngoing(false);
                    dispatch.main.SetSuccess();
                } catch (err) {
                    this.updateOngoing(false);
                    dispatch.main.SetError(err);
                }
            },
            async UpSertService(data, payload) {
                this.updateOngoing(true);
                try {
                    await Request.UpSertService(data);
                    this.updateOngoing(false);
                    await this.GetServices(data.customerId);
                    dispatch.main.SetSuccess();
                } catch (err) {
                    this.updateOngoing(false);
                    dispatch.main.SetError(err);
                }
            },
            async GetOffices(id) {
                try {
                    const result = await Request.OfficesList(id);
                    this.updateOffices(result.data.response !== null ? result.data.response : []);
                } catch (err) {
                    dispatch.main.SetError(err);
                }
            },
            async GetOfficeServices(officeId) {
                try {
                    const result = await Request.OfficeServicesList(officeId);
                    this.updateOfficeServices(result.data.response !== null ? result.data.response : []);
                } catch (err) {
                    dispatch.main.SetError(err);
                }
            },
            async GetAccount(id) {
                try {
                    const result = await Request.GetCustomer(id);
                    this.setCurrentCustomer(result.data.response);
                } catch (err) {
                    dispatch.main.SetError(err);
                }
            },

            async GetServices(id) {
                try {
                    const result = await Request.ServicesList(id);
                    this.updateServices(result.data.response !== null ? result.data.response : []);
                } catch (err) {
                    dispatch.main.SetError(err);
                }
            },

            async GetLogos() {
                try {
                    const result = await Request.GetLogos();
                    this.updateLogos(result.data.response !== null ? result.data.response : []);
                    //this.updateLogos(result);
                } catch (err) {
                    dispatch.main.SetError(err);
                }
            },
        })
    });

const setError = (message) => {
    return {
        id: Date.now(),
        message
    }
};
