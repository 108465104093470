import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import './index.css';
import App from './App';
import {initStore} from "./models/store";
import {I18nextProvider} from "react-i18next";
import i18next from './translation/i18n'

const store = initStore();

const Root = () => (
    <Provider store={store}>
        <I18nextProvider i18n={i18next}>
        <App/>
        </I18nextProvider>
    </Provider>
);

ReactDOM.render(<Root/>, document.querySelector("#root"));
