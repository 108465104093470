import React from "react";
import SideNav, {NavItem, NavIcon, NavText} from '@trendmicro/react-sidenav';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faHome,
    faOutdent, faLocationArrow, faCheckDouble, faCar,
    faList,
} from '@fortawesome/free-solid-svg-icons'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import {useTranslation} from "react-i18next";
import FONTS from "../utils/fonts";
import {AccountCircle} from "@material-ui/icons";
import {UserPlus} from 'react-feather';


const SideMenu = (props) => {
    const { t } = useTranslation();

    return (
        <SideNav onSelect={props.onSelect} onToggle={props.onToggle} expanded={props.expanded} className="menu-style" style={{height: props.height}}>
            <SideNav.Toggle/>
            <SideNav.Nav selected={props.selected}>
                <NavItem eventKey="menu_dashboard">
                    <NavIcon>
                        <FontAwesomeIcon icon={faHome}/>
                    </NavIcon>
                    <NavText style={{paddingRight: 32}} title= {t('menu.dashboard')}>
                        {t('menu.dashboard')}
                    </NavText>
                </NavItem>
                <NavItem eventKey="menu_location">
                    <NavIcon>
                        <FontAwesomeIcon icon={faLocationArrow}/>
                    </NavIcon>
                    <NavText style={{paddingRight: 32, fontFamily: FONTS.Bold}} title= {t('menu.location')}>
                        {t('menu.location')}
                    </NavText>
                    <NavItem eventKey="menu_country">
                        <NavText title={t('menu.country')}>
                            {t('menu.country')}
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="menu_town">
                        <NavText title={t('menu.town')}>
                            {t('menu.town')}
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="menu_district">
                        <NavText title={t('menu.district')}>
                            {t('menu.district')}
                        </NavText>
                    </NavItem>
                </NavItem>
                {/* <NavItem eventKey="menu_car">
                    <NavIcon>
                        <FontAwesomeIcon icon={faCar}/>
                    </NavIcon>
                    <NavText style={{paddingRight: 32, fontFamily: FONTS.Bold}} title= {t('menu.car')}>
                        {t('menu.car')}
                    </NavText>
                    <NavItem eventKey="menu_brand">
                        <NavText title={t('menu.brand')}>
                            {t('menu.brand')}
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="menu_model">
                        <NavText title={t('menu.model')}>
                            {t('menu.model')}
                        </NavText>
                    </NavItem>
                </NavItem> */}
                <NavItem eventKey="menu_validation">
                    <NavIcon>
                        <FontAwesomeIcon icon={faCheckDouble}/>
                    </NavIcon>
                    <NavText style={{paddingRight: 32, fontFamily: FONTS.Bold}} title= {t('menu.validation')}>
                        {t('menu.validation')}
                    </NavText>
                    <NavItem eventKey="menu_rp_validation">
                        <NavText title={t('menu.rp_validation')}>
                            {t('menu.rp_validation')}
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="menu_carrier_validation">
                        <NavText title={t('menu.carrier_validation')}>
                            {t('menu.carrier_validation')}
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="menu_product_validation">
                        <NavText title={t('menu.product_validation')}>
                            {t('menu.product_validation')}
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="menu_item_validation">
                        <NavText title={t('menu.item_validation')}>
                            {t('menu.item_validation')}
                        </NavText>
                    </NavItem>
                </NavItem>
               
                <NavItem eventKey="menu_list">
                    <NavIcon>
                        <FontAwesomeIcon icon={faList}/>
                    </NavIcon>
                    <NavText style={{paddingRight: 32}} title={t('menu.list')}>
                        {t('menu.list')}
                    </NavText>
                    <NavItem eventKey="menu_rp_list">
                        <NavText title={t('menu.rp_list')}>
                            {t('menu.rp_list')}
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="menu_carrier_list">
                        <NavText title={t('menu.carrier_list')}>
                            {t('menu.carrier_list')}
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="menu_store_list">
                        <NavText title={t('menu.store_list')}>
                            {t('menu.store_list')}
                        </NavText>
                    </NavItem>
                </NavItem>
                 {/*
                <NavItem eventKey="menu_office">
                    <NavIcon>
                        <FontAwesomeIcon icon={faPlaceOfWorship}/>
                    </NavIcon>
                    <NavText style={{paddingRight: 32}} title= {t('menu_office')}>
                        {t('menu_office')}
                    </NavText>
                </NavItem>
                <NavItem eventKey="menu_service">
                    <NavIcon>
                        <FontAwesomeIcon icon={faServer}/>
                    </NavIcon>
                    <NavText style={{paddingRight: 32}} title={t('menu_service')}>
                        {t('menu_service')}
                    </NavText>
                </NavItem>
                <NavItem eventKey="menu_settings">
                    <NavIcon>
                        <FontAwesomeIcon icon={faCog}/>
                    </NavIcon>
                    <NavText style={{paddingRight: 32}} title= {t('menu_settings')}>
                        {t('menu_settings')}
                    </NavText>
                </NavItem> */}
                <NavItem eventKey="menu_manage_user">
                    <NavIcon>
                        <UserPlus/>
                    </NavIcon>
                    <NavText style={{paddingRight: 32, fontFamily: FONTS.Bold}} title= {t('menu.manage_user')}>
                        {t('menu.manage_user')}
                    </NavText>
                </NavItem>
                <NavItem eventKey="menu_profile">
                    <NavIcon>
                        <AccountCircle/>
                    </NavIcon>
                    <NavText style={{paddingRight: 32}} title= {t('menu.profile')}>
                        {t('menu.profile')}
                    </NavText>
                </NavItem>
                <NavItem eventKey="menu_logout">
                    <NavIcon>
                        <FontAwesomeIcon icon={faOutdent}/>
                    </NavIcon>
                    <NavText style={{paddingRight: 32}} title= {t('menu.logout')}>
                        {t('menu.logout')}
                    </NavText>
                </NavItem>
            </SideNav.Nav>
        </SideNav>
    )
};


export default SideMenu;
