import React from 'react';
//hooks
import Activation from "./activation";
import ChangePasswordScreen from "./changePassword";
import Update from "./update";

const ProfileScreen = () => {

    return (
            <>
                <Activation/>
                <ChangePasswordScreen/>
                <Update />
            </>
    );
};


export default ProfileScreen
