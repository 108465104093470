import Colors from "../utils/Colors";
import FONTS from "../utils/fonts";

export const AppStyles = {
    cardH: {
        backgroundColor: '#373737',
        color: Colors.white,
        fontFamily: FONTS.Regular,
        fontSize: 10,
        paddingLeft: 30,
    },

    greyBar: {
        backgroundColor: Colors.clearGray,
        border: 2,
        height: 40,
    },
    greyBarText: {
        color: Colors.black,
        fontSize: 12,
        fontFamily: FONTS.Regular,
    },
    validationButton: {
        backgroundColor: Colors.blueLight,
        color: Colors.white,
        height: 35,
        fontSize: 12,
        fontFamily: FONTS.Regular,
    },
    cancelButton: {
        height: 35,
        fontSize: 12,
        fontFamily: FONTS.Regular,
        borderColor: Colors.black,
        borderWidth: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    modifyButtonText: {
        color: Colors.blueLight,
        fontSize: 12,
        fontFamily: FONTS.Regular,
    },
    normalIcon: {
        color: Colors.black,
    },
    ActiveIcon: {
        color: Colors.blueLight,
    },
    modifyButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    linkButton: {
        backgroundColor: Colors.white,
        borderColor: Colors.blueLight,
        color: Colors.blueLight,
    },
    loginInput: {
        paddingLeft: 30,
        paddingRight: 30,
        fontFamily: FONTS.Regular,
        fontSize: 20,
    },
    accordionHeader: {
        height: 40,
        fontFamily: FONTS.Regular,
        fontSize: 11
    },
    homeAccordionHeader: {
        height: 40,
        fontFamily: FONTS.Regular,
        fontSize: 11,
        paddingLeft: 40,
    },
    homeCalendarHeaderText: {
        fontFamily: FONTS.Regular,
        fontSize: 11,
        fontWeight: "bold",
    },
    homeCalendarTableHeaderBlack: {
        fontFamily: FONTS.Regular,
        fontSize: 10,
        color: Colors.white,
        backgroundColor: "#373737",
        padding: 10,
        textAlign: "center",
    },
    homeCalendarTableHeaderBlue: {
        fontFamily: FONTS.Regular,
        fontSize: 10,
        color: Colors.white,
        backgroundColor: Colors.meanBlue,
        padding: 10,
        width: 60,
        textAlign: "center",
    },
    homeCalendarTableBodyGrey: {
        fontFamily: FONTS.Regular,
        fontSize: 10,
        color: Colors.black,
        backgroundColor: Colors.grey,
        padding: 10,
        textAlign: "center",
        width: 100,
    },
    homeCalendarTableBody: {
        textAlign: "center",
    },
    homeCalendarContentText: {
        fontFamily: FONTS.Regular,
        fontSize: 10,
        color: Colors.black,
    },
    homeCalendarButtonGroupText: {
        fontFamily: FONTS.Regular,
        fontSize: 10,
    },
    Select: {
        control: styles => ({
            ...styles,
            borderRadius: 0,
            marginTop: 5,
            fontSize: 12,
            borderWidth: 0,
            borderBottomWidth: 1,
            borderBottomColor: '#949494',
        }),
        multiValue: (styles) => {
            return {
                ...styles,
                backgroundColor: 'white',
                fontSize: 12,
            };
        },
        option: (styles) => {
            return {
                ...styles,
                fontSize: 12,
                fontFamily: FONTS.Regular,
            }
        }
    },
    input: {
            style: {fontSize: 12}
    },
};
