import { LocationModel } from './location'
import { CustomerModel } from "./customer";
import { UserModel } from "./user";
// import { CalendarModel } from "./calendar";
import { MainModel } from "./main";
import { PreferenceModel } from "./preference";
// import { StatisticModel } from "./statistic";
import {ValidationModel} from "./validations";
// import {CarModel} from "./car";

export const initModels = () => {
    return {
        // calendar: CalendarModel(),
        customer: CustomerModel(),
        // statistic: StatisticModel(),
        location: LocationModel(),
        main: MainModel(),
        preference: PreferenceModel(),
        user: UserModel(),
        validations: ValidationModel(),
        // car: CarModel(),
    };
};
