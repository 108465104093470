import { createModel } from "@rematch/core";
import * as Request from "../queries/validations";

export const ValidationModel = () =>
    createModel({
        state: {
            ongoing: false,
            autos: [],
            houses: [],
        },
        reducers: {
            errors(state, err) {
                return {...state, error: err}
            },
            updateOngoing(state, ok) {
                return {...state, ongoing: ok}
            },
            updateAnnounces(state, data) {
                return {...state, ongoing: false, autos: data}
            },
            updateHouses(state, h) {
                return {...state, ongoing: false, houses: h}
            },
        },

        effects: dispatch => ({
            // async LoadAutoToValidate() {
            //     this.updateOngoing(true);
            //     try {
            //         const result = await Request.AutoToValidate();
            //         this.updateAnnounces(result.data !== null ? result.data : []);
            //     } catch (err) {
            //         this.updateOngoing(false);
            //         dispatch.main.SetError(err);
            //     }
            // },

            // async ValidateAuto(payload) {
            //     this.updateOngoing(true);
            //     try {
            //         await Request.ValidateAuto(payload);
            //         this.updateOngoing(false);
            //         await this.LoadAutoToValidate();
            //         dispatch.main.SetSuccess();
            //     } catch (err) {
            //         this.updateOngoing(false);
            //         dispatch.main.SetError(err);
            //     }
            // },

            // async LoadHousesToValidate() {
            //     this.updateOngoing(true);
            //     try {
            //         const result = await Request.HouseToValidate();
            //         this.updateHouses(result.data !== null ? result.data : []);
            //     } catch (err) {
            //         this.updateOngoing(false);
            //         dispatch.main.SetError(err);
            //     }
            // },

            // async ValidateHouse(payload) {
            //     this.updateOngoing(true);
            //     try {
            //         await Request.ValidateHouse(payload);
            //         this.updateOngoing(false);
            //         await this.LoadHousesToValidate();
            //         dispatch.main.SetSuccess();
            //     } catch (err) {
            //         this.updateOngoing(false);
            //         dispatch.main.SetError(err);
            //     }
            // },
        })
    });
