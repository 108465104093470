import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useSelector} from "react-redux";

//hooks
import useRematchDispatch from "../hooks/useRematchDispatch";
import {useTranslation} from "react-i18next";
//services
//components
import SideMenu from "../navigation/sideMenu";
import {AlertMessage, Alerts} from "../components/alert";
//screens
import HomeScreen from "./homeScreen";
import CountryScreen from "./location/countryScreen";
import TownScreen from "./location/townScreen";
import DistrictScreen from "./location/districtScreen";
import Header from "../components/header";
import AppBar from "@material-ui/core/AppBar";
import ProfileScreen from "./user/profileScreen";
import UserScreen from "./user/userScreen";
import CarrierValidationScreen from "./validations/carrierValidationScreen";
import BrandScreen from "./car/brandScreen";
import ModelScreen from "./car/modelScreen";
import RelayPointValidationScreen from './validations/relayPointValidationScreen';
import ProductValidationScreen from './validations/productValidationScreen';
import ItemValidationScreen from './validations/itemValidationScreen';
import RelayPointListScreen from './relayPointList';
import CarrierListScreen from './carrierList';
import StoreListScreen from './storeList';

const MainScreen = () => {
    const {t} = useTranslation();

    const {GetAccount, LoadLocalData} = useRematchDispatch(dispatch => ({
        // GetAccount: dispatch.customer.GetAccount,
        LoadLocalData: dispatch.preference.LoadLocalData,
    }));

    const error = useSelector(state => state.main.error);
    const success = useSelector(state => state.main.success);
    const currentUser = useSelector(state => state.preference.user);

    const [selected, setSelected] = useState('menu_dashboard');
    const [expanded, setExpanded] = useState(false);
    const [alert, setAlert] = useState({
        id: Date.now(),
        show: false,
        message: "",
        heading: "",
        type: ""
    });
    const [menuHeight, setMenuHeight] = useState();
    const screens = {
        menu_dashboard: HomeScreen,
        menu_country: CountryScreen,
        menu_town: TownScreen,
        menu_district: DistrictScreen,
        // menu_store_validation: HouseScreen,
        menu_rp_validation: RelayPointValidationScreen,
        menu_carrier_validation: CarrierValidationScreen,
        menu_manage_user: UserScreen,
        menu_profile: ProfileScreen,
        menu_brand: BrandScreen,
        menu_model: ModelScreen,
        menu_product_validation: ProductValidationScreen,
        menu_item_validation: ItemValidationScreen,
        menu_rp_list: RelayPointListScreen,
        menu_carrier_list: CarrierListScreen,
        menu_store_list: StoreListScreen
    };

    useLayoutEffect(() => {
        const h = document.body.scrollHeight > window.innerHeight ? document.body.scrollHeight : window.innerHeight;
        setMenuHeight(h);
    });


    useEffect(() => {
        LoadLocalData();
    }, []);

    useEffect(() => {
        if (error !== null) {
            setAlert(a => AlertMessage(t(error.message), error.id, "danger"));
        }
    }, [error]);

    useEffect(() => {
        if (success !== null) {
            setAlert(a => AlertMessage(t('success_message'), success, "success"));
            setTimeout(() => {
                setAlert({...alert, show: false})
            }, 5000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success]);

    const onSelect = (selected) => {
        if (selected === "menu_logout") {
            localStorage.clear();
            window.location.href = '/';
        } else {
            setSelected(selected);
            setExpanded(false);
        }
    };
    const onToggle = (expanded) => {
        setExpanded(expanded)
    };
    const InnerScreen = screens[selected] !== undefined ? screens[selected] : screens['menu_dashboard'];
    return (
        <div style={styles.root}>
            <header>
                <AppBar>
                    <Header userName={'Admin'}
                            pageName={t(`labels.${selected}`)}/>
                </AppBar>
            </header>
            <nav>
                <SideMenu selected={selected} onSelect={onSelect} onToggle={onToggle} expanded={expanded}
                          height={menuHeight}/>
            </nav>
            <main style={styles.wrapper}>
                <div style={styles.contentContainer}>
                    <article style={styles.content}>
                        <InnerScreen/>
                    </article>
                </div>
            </main>
            <Alerts key={alert.id} show={alert.show} type={alert.type} message={alert.message} heading={alert.heading}
                    onClose={() => {
                    }}/>
        </div>
    );
};

const styles = {
    root: {
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'auto',
        paddingTop: 64,
        marginLeft: 100,
        backgroundColor: '#fff',
        height: '100vh',
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'auto',
        height: '100%',

    },
    content: {
        flex: 'auto',
        height: '100%',
        overflow: 'auto',
        paddingRight: 20,
    }
};

export default MainScreen
