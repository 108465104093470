import React from 'react';
import {Col, Row} from "react-bootstrap";
import Colors from "../utils/Colors";
import FONTS from "../utils/fonts";

import {useTranslation} from "react-i18next";


const Footer = () => {
  const {t} = useTranslation();
    return (
        <>
            <Row style={styles.footer} className="linearGradient text-center">
                <Col>
              <span style={styles.link} className="name-link">
                <a href="https://freepin.ilema.co">Termes et conditions</a> | {'Copyright © '}Freepin{' '}{new Date().getFullYear()}{'.'}
              </span>
                </Col>
            </Row>
        </>
    );
};

const styles = {
    confidence: {
        color: Colors.meanBlue,
        fontFamily: FONTS.SemiBold,
        fontSize: 13,
    },
    link: {
        color: Colors.white,
        fontFamily: FONTS.Regular,
        fontSize: 11,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        height: 30,
        width: '100%'
    },

};
export default Footer;
