import { createModel } from "@rematch/core";
import *as Request from "../queries/location";

export const LocationModel = () =>
    createModel(
        {
            state: {
                countries: [],
                towns: [],
                districts: [],
                locations:[]
            },
            reducers: {
                updateCountries(state, countries) {
                    return {...state, countries: countries}
                },
                updateLocations(state, locs) {
                    return {...state, locations: locs}
                },
                updateTowns(state, towns) {
                    return {...state, towns};
                },
                updateDistricts(state, districts) {
                    return {...state, districts};
                },
            },

            effects: dispatch => ({
                async CreateCountry(payload) {
                    dispatch.main.SetProcessing(true);
                    try {
                        await Request.CreateCountry(payload);
                        await this.LoadCountries();
                        dispatch.main.SetSuccess();
                    } catch (err) {
                        dispatch.main.SetError(err);
                    }
                },

                async DeleteCountry(id) {
                    dispatch.main.SetProcessing(true);
                    try {
                        await Request.DeleteCountry(id);
                        await this.LoadCountries();
                        dispatch.main.SetSuccess();
                    } catch (err) {
                        dispatch.main.SetError(err);
                    }
                },
                async CreateTown(payload) {
                    dispatch.main.SetProcessing(true);
                    try {
                        await Request.CreateTown(payload);
                        await this.LoadTowns(payload.countryId);
                        dispatch.main.SetSuccess();
                    } catch (err) {
                        dispatch.main.SetError(err);
                    }
                },

                async DeleteTown(payload) {
                    dispatch.main.SetProcessing(true);
                    try {
                        await Request.DeleteTown(payload.townId);
                        await this.LoadTowns(payload.countryId);
                        dispatch.main.SetSuccess();
                    } catch (err) {
                        dispatch.main.SetError(err);
                    }
                },

                async CreateDistrict(payload) {
                    dispatch.main.SetProcessing(true);
                    try {
                        await Request.CreateDistrict(payload);
                        await this.LoadDistricts(payload.townId);
                        dispatch.main.SetSuccess();
                    } catch (err) {
                        dispatch.main.SetError(err);
                    }
                },

                async DeleteDistrict(payload) {
                    dispatch.main.SetProcessing(true);
                    try {
                        await Request.DeleteDistrict(payload.districtId);
                        await this.LoadDistricts(payload.townId);
                        dispatch.main.SetSuccess();
                    } catch (err) {
                        dispatch.main.SetError(err);
                    }
                },

                async LoadCountries() {
                    try {
                        const result = await Request.FetchCountries();
                        this.updateCountries(
                            result.data !== null ? result.data : [],
                        );
                    } catch (err) {}
                },

                async LoadTowns(countryId) {
                    try {
                        const result = await Request.FetchTowns(countryId);
                        this.updateTowns(
                            result.data !== null ? result.data : [],
                        );
                    } catch (err) {
                        console.log(err);
                    }
                },
                async LoadDistricts(townId) {
                    try {
                        const result = await Request.FetchDistricts(townId);
                        this.updateDistricts(
                            result.data !== null ? result.data : [],
                        );
                    } catch (err) {}
                },
                async LoadLocations(payload, rootState) {
                    try {
                        const result = await Request.FetchLocations();
                        this.updateLocations(result.data);
                    } catch (err) {
                        dispatch.main.SetError(err)
                    }
                }
            })
        });
