import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Button, Form, FormControl, Spinner, Table } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//hooks
import useRematchDispatch from "../../hooks/useRematchDispatch";

//services
import Colors from "../../utils/Colors";
import { useTranslation } from "react-i18next";

const CountryScreen = () => {
    const { t } = useTranslation();
    const { CreateCountry, DeleteCountry, LoadCountries } = useRematchDispatch(dispatch => ({
        CreateCountry: dispatch.location.CreateCountry,
        LoadCountries: dispatch.location.LoadCountries,
        DeleteCountry: dispatch.location.DeleteCountry,
    }));
    const processing = useSelector(state => state.main.processing);
    const countries = useSelector(state => state.location.countries);
    const [inputs, setInputs] = useState({
        name: '',
        isoCode: '',
        indicator: '',
        id: null,
    });

    const [errors, setErrors] = useState({
        name: false,
    });
    const [selectedId, setSelectedId] = useState();

    useEffect(() => {
        LoadCountries();

    }, []);

    useEffect(() => {
        const selectedCountry = countries.find(x => x.id === selectedId);

        if (selectedCountry !== undefined) {
            setInputs(selectedCountry)
        }
    }, [selectedId]);


    const handleClick = () => {
        if (inputs.name.length === 0) {
            setErrors(errs => ({ ...errs, "name": true }));
            return;
        } else {
            setErrors(errs => ({ ...errs, "name": false }));
        }
        if (inputs.isoCode.length === 0) {
            setErrors(errs => ({ ...errs, "isoCode": true }));
            return;
        } else {
            setErrors(errs => ({ ...errs, "isoCode": false }));
        }
        if (inputs.indicator.length === 0) {
            setErrors(errs => ({ ...errs, "indicator": true }));
            return;
        } else {
            setErrors(errs => ({ ...errs, "indicator": false }));
        }
        CreateCountry(inputs);
    };

    const handleNewClick = () => {
        let empty = {};
        for (const input in inputs) {
            empty[input] = "";
        }
        setInputs(empty);
        setSelectedId("");
    };

    const inputStyle = {
        marginBottom: 10
    };
    const renderCountries = () => {
        return (
            <Table bordered hover>
                <thead>
                    <tr className="text-center" bgcolor={Colors.greyhish}>
                        <th>{t('name')}</th>
                        <th>{t('iso_code')}</th>
                        <th>{t('indicator')}</th>
                    </tr>
                </thead>
                <tbody>
                    {countries.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1).map((x) =>
                        <tr key={x.id} bgcolor={x.id === selectedId ? Colors.defaultBackground : Colors.white}
                            onClick={() => {
                                setSelectedId(x.id)
                            }} style={{ cursor: 'pointer' }}>
                            <td>{x.name}</td>
                            <td>{x.isoCode}</td>
                            <td>{x.indicator}</td>
                            <td><Button disabled={true} onClick={() => {
                                DeleteCountry(x.id)
                            }} variant="outline-dark">Supprimer</Button></td>
                        </tr>)}
                </tbody>
            </Table>
        )
    };
    const onPropertyValueChange = (event) => {
        const value = event.target.value;
        setInputs({ ...inputs, [event.target.id]: value.toUpperCase() });
    };
    return (
        <main className="container w-50">
            <div className="justify-content-center">
                <div>
                    <Form.Group as={Row}>
                        <Form.Label column sm={3}> {t('name')}   </Form.Label>
                        <Col sm={9}><FormControl id="name" type="text" placeholder={t('name')} className="mr-sm-2"
                            style={inputStyle} isInvalid={errors.name}
                            onChange={(event) => onPropertyValueChange(event)}
                            value={inputs.name}
                        />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={3}> {t('iso_code')}   </Form.Label>
                        <Col sm={9}><FormControl id="isoCode" type="text" placeholder={t('iso_code')} className="mr-sm-2"
                            style={inputStyle} isInvalid={false}
                            onChange={(event) => onPropertyValueChange(event)}
                            value={inputs.isoCode}
                        />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={3}> {t('indicator')}   </Form.Label>
                        <Col sm={9}><FormControl id="indicator" type="text" placeholder={t('indicator')} className="mr-sm-2"
                            style={inputStyle} isInvalid={false}
                            onChange={(event) => onPropertyValueChange(event)}
                            value={inputs.indicator}
                        />
                        </Col>
                    </Form.Group>
                    <div className="text-center">

                        <Button variant="outline-dark" style={{ marginRight: 10, backgroundColor: Colors.greyhish }}
                            onClick={() => {
                                handleNewClick()
                            }}>{t('new')}</Button>

                        <Button variant="outline-dark" onClick={() => {
                            handleClick()
                        }}> {processing ?
                            <Spinner as="span" animation="grow" size="sm" role="status"
                                aria-hidden="true" /> : null}{t('validate')}</Button>
                    </div>

                </div>
                <span className="space" />
                <div>{renderCountries()}</div>
            </div>
        </main>
    );
};

export default CountryScreen
