export default {
  error: 'red',
  primary: '#d04c40',
  secondary: '#e9b25f',
  white: '#FFF',
  dark: '#444',
  black: '#000',
  defaultBackground: '#F5F5F5',
  greyhish: '#F1F5F7',
  whiteTwo: '#F5F5F5',
  blueOne: '#0094C1',
  blueLight: '#00AEF1',
  grey: '#cbced1',
  darkgreen: 'darkgreen',
  meanBlue: '#174195',
  royalblue: '#2364E6',
  lightblue: '#049BE9',
  clearGray: '#E9E8E8',
  darkGrayOne: '#707070',
  darkGrayTwo: '#636770',
};
