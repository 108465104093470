import React, { useEffect, useState } from 'react';
import useRematchDispatch from "../hooks/useRematchDispatch";
// import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { LoadCorporateInformation } from '../queries/main';
import { endOfMonth, startOfMonth } from 'date-fns';

const HomeScreen = () => {
    const { GetDayCorporate } = useRematchDispatch(dispatch => ({
        GetDayCorporate: dispatch.main.GetDayCorporate,
    }));
    // const corporateInfo = useSelector(state => state.main.corporateInfo);
    const [corporateInfo, setCorporateInfo]= useState([])

    useEffect(() => {
        load();
    }, [])

    const load = async () => {
       
        try {
            const data = await LoadCorporateInformation({start: startOfMonth(Date.now()), end: endOfMonth(Date.now())});
            setCorporateInfo(data)  
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <main>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>{'Date'}</th>
                        <th>{'Utilisateurs'}</th>
                        <th>{'Boutiques'}</th>
                        <th>{'Produits privés'}</th>
                        <th>{'Produits boutique'}</th>
                        <th>{'Livreurs'}</th>
                        <th>{'Points relais'}</th>

                    </tr>
                </thead>

                <tbody>
                {corporateInfo.map((x) =>
                    <tr key={x.date}>
                    <td>{x?.date}</td>
                        <td>{x?.user}</td>
                        <td>{x?.store}</td>
                        <td>{x?.product}</td>
                        <td>{x?.item}</td>
                        <td>{x?.carrier}</td>
                        <td>{x?.point}</td>
                    </tr>
                    )}
                </tbody>
            </Table>

        </main>
    );
};


export default HomeScreen;
