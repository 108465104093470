import React from 'react';

import './App.css';
import './css/font.css'
import './css/common.css';
import 'bootstrap/dist/css/bootstrap.css';

import {ApplicationRoutes} from "./navigation/appRoutes";

const App = () => {
    return (
        <ApplicationRoutes/>
    );
}
export default App;
