import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Button, Col, Row, Spinner} from "react-bootstrap";
//hooks
import useRematchDispatch from "../../hooks/useRematchDispatch";
import {useTranslation} from "react-i18next";
import {Check} from "react-feather";
import Colors from "../../utils/Colors";
import {AppStyles} from "../../css/styles";
import TextField from "@material-ui/core/TextField";


const Activation = () => {
    const {t} = useTranslation();
    const {ActivateUser} = useRematchDispatch(dispatch => ({
        ActivateUser: dispatch.user.Activate,
    }));

    const ongoing = useSelector(state => state.user.ongoing);
    const [invalid, setInvalid] = useState(false);
    let activationCode = "";


    const handleClick = () => {
        if (activationCode === "") {
            setInvalid(true);
        } else {
            ActivateUser({activationCode});
            setInvalid(false);
        }
    };

    return (
        <>
            <Row className="greyBar">
                <Col className="d-flex align-items-center">
                    <Check style={{color: Colors.black}} size={14}/> <span style={AppStyles.greyBarText}
                                                                 className="ml-2"> {t('labels.menu_profile_activation')}</span>
                </Col>
            </Row>
            <Row className="text-center">
                <Col xs={12} md={2}/>
                <Col xs={8} md={3}>
                    <TextField type="text" label={t('activation_code')} error={invalid}
                               onChange={(event) => {
                                   activationCode = event.target.value
                               }}
                               size="medium" className="w-100"
                               InputProps={AppStyles.input} InputLabelProps={AppStyles.input}/>
                </Col>

            </Row>
            <Row className="text-center mt-4 mb-4">
                <Col xs={12} md={2}/>
                <Col xs={8} md={3}>
                    <Button className="w-100" onClick={() => {handleClick()}} style={AppStyles.validationButton}>
                        {t('validate_code')}
                    </Button>
                </Col>
            </Row>
        </>
    );
};


export default Activation
