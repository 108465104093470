import axios from 'axios';
import {SERVER_URL} from "../app-config";
// axios.defaults.baseURL = Config.API_HOST;
axios.defaults.baseURL = SERVER_URL;
// axios.defaults.baseURL = "http://localhost:8292";

axios.interceptors.request.use(
    config => {
        config.headers.authorization = `Bearer ${localStorage.getItem("Token")}`;
        return config
    },
    error => {
        return Promise.reject(error);
    }
);

export default axios;
