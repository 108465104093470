import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import {Button, Form, FormControl, ListGroup, ListGroupItem, Spinner, Table} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//hooks
import useRematchDispatch from "../../hooks/useRematchDispatch";

//services
import Colors from "../../utils/Colors";
import { useTranslation } from "react-i18next";

const TownScreen = () => {
    const {t} = useTranslation();
    const {CreateTown, LoadTowns, LoadCountries} = useRematchDispatch(dispatch => ({
        CreateTown: dispatch.location.CreateTown,
        LoadTowns: dispatch.location.LoadTowns,
        LoadCountries: dispatch.location.LoadCountries,
    }));
    const processing = useSelector(state => state.main.processing);
    const towns = useSelector(state => state.location.towns);
    const countries = useSelector(state => state.location.countries);
    const [inputs, setInputs] = useState({
        name: '',
        longitude: '',
        latitude: '',
        id: null,
    });

    const [errors, setErrors] = useState({
        name: false,
    });
    const [selectedId, setSelectedId] = useState();
    const [countryId, setCountryId] = useState();

    useEffect(() => {
        LoadCountries();

    }, []);

    useEffect(() => {
        const selectedCountry = towns.find(x => x.id === selectedId);

        if (selectedCountry !== undefined) {
            setInputs(selectedCountry)
        }
    }, [selectedId]);

    useEffect(() => {
        if (countryId) {
            LoadTowns(countryId);
        }
    }, [countryId]);


    const handleClick = () => {
        if (inputs.name.length === 0) {
            setErrors(errs => ({...errs, "name": true}));
            return;
        } else {
            setErrors(errs => ({...errs, "name": false}));
        }
        if (inputs.longitude === 0) {
            setErrors(errs => ({...errs, "longitude": true}));
            return;
        } else {
            setErrors(errs => ({...errs, "longitude": false}));
        }
        if (inputs.latitude === 0) {
            setErrors(errs => ({...errs, "latitude": true}));
            return;
        } else {
            setErrors(errs => ({...errs, "latitude": false}));
        }
        inputs.countryId = countryId;
        if (inputs.id) inputs.id.toString()
        CreateTown(inputs);
    };

    const handleNewClick = () => {
        let empty = {};
        for (const input in inputs) {
            let value = "";
            if (input === 'longitude' ||input === 'latitude') {
                value = 0;
            }
            empty[input] = value;
        }
        setInputs(empty);
        setSelectedId("");
    };

    const inputStyle = {
        marginBottom: 10
    };

    const onPropertyValueChange = (event) => {
        let value = event.target.value;
        if (event.target.id === 'longitude' || event.target.id === 'latitude') {
            value = parseFloat(value);
            setInputs({...inputs, [event.target.id]: value});
        } else {
            setInputs({...inputs, [event.target.id]: value.toUpperCase()});
        }

    };

    const renderCountries = () => {
        return (
            <main className="card">
                <div className="card-header">
                    {t('countries')}
                </div>
                <div className="card-body">
                        {countries.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1).map(x =>
                            <Button style={{margin: 5}} key={x.id}
                                    variant={`${x.id === countryId ? "info" : ""}`}
                                    onClick={() => setCountryId(x.id)}
                            >
                                {x.name.toUpperCase()}
                            </Button>
                        )}
                </div>
            </main>
        )
    };

    const renderTowns = () => {
        return (
            <Table bordered hover>
                <thead>
                <tr className="text-center" bgcolor={Colors.greyhish}>
                    <th>{t('name')}</th>
                    <th>{t('longitude')}</th>
                    <th>{t('latitude')}</th>
                </tr>
                </thead>
                <tbody>
                {towns.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1).map((x) =>
                    <tr key={x.id} bgcolor={x.id === selectedId ? Colors.defaultBackground : Colors.white}
                        onClick={() => {
                            setSelectedId(x.id)
                        }} style={{cursor: 'pointer'}}>
                        <td>{x.name}</td>
                        <td>{x.longitude}</td>
                        <td>{x.latitude}</td>
                    </tr>)}
                </tbody>
            </Table>
        )
    };

    return (
        <main>
            <div>{renderCountries()}</div>
            <div className="container w-50">
            <span className="space"/>
            <div className="justify-content-center">
                <Form.Group as={Row}>
                    <Form.Label column sm={3}> {t('name')}   </Form.Label>
                    <Col sm={9}><FormControl id="name" type="text" placeholder={t('name')} className="mr-sm-2"
                                             style={inputStyle} isInvalid={errors.name}
                                             onChange={(event) => onPropertyValueChange(event)}
                                             value={inputs.name}
                    />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={3}> {t('longitude')}   </Form.Label>
                    <Col sm={9}><FormControl id="longitude" type="number" placeholder={t('longitude')} className="mr-sm-2"
                                             style={inputStyle} isInvalid={errors.longitude}
                                             onChange={(event) => onPropertyValueChange(event)}
                                             value={inputs.longitude}
                    />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={3}> {t('latitude')}   </Form.Label>
                    <Col sm={9}><FormControl id="latitude" type="number" placeholder={t('latitude')} className="mr-sm-2"
                                             style={inputStyle} isInvalid={errors.latitude}
                                             onChange={(event) => onPropertyValueChange(event)}
                                             value={inputs.latitude}
                    />
                    </Col>
                </Form.Group>
                <div className="text-center">

                    <Button variant="outline-dark" style={{marginRight: 10, backgroundColor: Colors.greyhish}}
                            onClick={() => {
                                handleNewClick()
                            }}>{t('new')}</Button>

                    <Button variant="outline-dark" onClick={() => {
                        handleClick()
                    }} disabled={!countryId}> {processing ?
                        <Spinner as="span" animation="grow" size="sm" role="status"
                                 aria-hidden="true"/> : null}{t('validate')}</Button>
                </div>
            </div>
            <span className="space"/>
            <div>{renderTowns()}</div>
            </div>
        </main>
    );
};

export default TownScreen
