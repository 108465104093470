import axios from './axios'

const BASE = "/api/v1/member";

export const Create = async (data) => {
    return axios.post(BASE+"/create", data)
};

export const Activation = async (data) => {
    return axios.post(BASE+"/activate", data)
};

export const ChangePassword = async (data) => {
    return axios.post(BASE+"/change-password", data)
};

export const UsersList = async () => {
    return axios.get(BASE + "/members")
};

export const Login = async (data) => {
    return axios.post("/admin/login", data)
};

export const Grant = async (data) => {
    return axios.post(BASE+"/grant", data)
};

export const UserPrivileges = async (userId) => {
    return axios.get(BASE + "/privileges", {
        params: {
            userId
        }
    });
};


export const UserGet = async () => {
    return axios.get(BASE)
};

export const UserUpdate = async (data) => {
    return axios.put(BASE, data)
};

