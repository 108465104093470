import { createModel } from "@rematch/core";

export const PreferenceModel = () =>
    createModel({
        state: {
            user: {},
            customerId: null,
        },
        reducers: {
            updateCurrentUser(state, user) {
                return {...state, user: user}
            },
            updateUsers(state, users) {
                return {...state, users: users}
            },
        },

        effects: dispatch => ({
            async LoadLocalData() {
                const user = localStorage.getItem("user");
                this.updateCurrentUser(JSON.parse(user));
            },
             SetPreferences(data) {
                 const user = {firstName: data.firstName, lastName: data.lastName ? data.lastName: ""};
                 this.updateCurrentUser(user);
                 localStorage.setItem("Token", data.token);
                 localStorage.setItem("customerId", data.CustomerId);
                 localStorage.setItem("user", JSON.stringify(user));
            },
        })
    });
