import { createModel } from "@rematch/core";
import * as Request from "../queries/user";
import {Login as LoginRequest} from "../queries/user";
import {GenerateError} from "../utils/errors";

export const UserModel = () =>
    createModel({
        state: {
            ongoing: false,
            error: null,
            users: [],
            privileges: [],
            redirect: false,
            user: {},
        },
        reducers: {
            errors(state, err) {
                return {...state, error: err, ongoing: false}
            },
            inProgress(state, l, r) {
                return {...state, onLoading: l, redirect: r}
            },
            updateUsers(state, users) {
                return {...state, users: users}
            },
            updatePrivileges(state, pv) {
                return {...state, privileges: pv}
            },
            updateUser(state, user) {
                return {...state, user: user}
            },
        },

        effects: dispatch => ({
            async Create(data, payload) {
                this.inProgress(true);
                try {
                    await Request.Create(data);
                    this.inProgress(false);
                    dispatch.main.SetSuccess();
                } catch (err) {
                    dispatch.main.SetError(err)
                }
            },

            async Activate(data, payload) {
                this.inProgress(true);
                try {
                    await Request.Activation(data);
                    this.inProgress(false);
                    dispatch.main.SetSuccess();
                } catch (err) {
                    this.inProgress(false);
                    dispatch.main.SetError(err)
                }
            },

            async Update(data) {
                this.inProgress(true);
                try {
                    await Request.UserUpdate(data);
                    this.inProgress(false);
                    dispatch.main.SetSuccess();
                    await this.Get();
                } catch (err) {
                    dispatch.main.SetError(err)
                }
            },

            async ChangePassword(data, payload) {
                this.inProgress(true);
                try {
                    await Request.ChangePassword(data);
                    this.inProgress(false);
                    dispatch.main.SetSuccess();
                } catch (err) {
                    this.inProgress(false);
                    dispatch.main.SetError(err)
                }
            },
            async GetUsers() {
                try {
                    const result = await Request.UsersList();
                    this.updateUsers(result.data !== null ? result.data : []);
                } catch (err) {
                    this.updateUsers([]);
                    dispatch.main.SetError(err)
                }
            },
            async Get() {
                try {
                    const result = await Request.UserGet();
                    this.updateUser(result.data.response);
                } catch (err) {
                    dispatch.main.SetError(err)
                }
            },

            async GetUserPrivileges(userId) {
                try {
                    const result = await Request.UserPrivileges(userId);
                    this.updatePrivileges(result.data.response !== null ? result.data.response : []);
                } catch (err) {
                    this.updatePrivileges([]);
                    dispatch.main.SetError(err)
                }
            },
/*
            async GetPrivileges(userId) {
                try {
                    const result = await Request.GetPrivileges(userId);
                    this.updateUserPV(result.data.response);
                } catch (err) {
                    dispatch.main.SetError(err)
                }
            }, */
            async GrantUser(data, payload) {
                this.inProgress(true);
                try {
                    await Request.Grant(data);
                    this.inProgress(false);
                    dispatch.main.SetSuccess();
                } catch (err) {
                    this.inProgress(false);
                    dispatch.main.SetError(err)
                }
            },

            async PostLogin(data) {
                this.inProgress(true);
                this.errors(null)
                try {
                    const result = await LoginRequest(data);
                    dispatch.preference.SetPreferences(result.data);
                    setLocalStorage(result.data);
                    this.inProgress(false, true)
                } catch (err) {
                    this.errors(GenerateError(err));
                }

            },
        })
    });
const setLocalStorage = (data) => {
    const user = JSON.stringify({firstName: data.firstName, lastName: data.lastName ? data.lastName: ""})
    localStorage.setItem("user", user);
    localStorage.setItem("Token", data.token);
    localStorage.setItem("CustomerId", data.CustomerId);
};