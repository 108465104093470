import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import {Button, Form, FormControl, ListGroup, ListGroupItem, Spinner, Table} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//hooks
import useRematchDispatch from "../../hooks/useRematchDispatch";

//services
import Colors from "../../utils/Colors";
import { useTranslation } from "react-i18next";

const BrandScreen = () => {
    const {t} = useTranslation();
    const {CreateBrand, LoadBrands} = useRematchDispatch(dispatch => ({
        CreateBrand: dispatch.car.CreateBrand,
        LoadBrands: dispatch.car.LoadBrands,
    }));
    const processing = useSelector(state => state.main.processing);
    const brands = useSelector(state => state.car.brands);
    //const countries = useSelector(state => state.location.countries);
    const [inputs, setInputs] = useState({
        name: '',
        id: null,
    });

    const [errors, setErrors] = useState({
        name: false,
    });
    const [selectedId, setSelectedId] = useState();

    useEffect(() => {
        LoadBrands();
    }, []);

    useEffect(() => {
        const selected = brands.find(x => x.id === selectedId);

        if (selected !== undefined) {
            setInputs(selected)
        }
    }, [selectedId]);

    const handleClick = () => {
        if (inputs.name.length === 0) {
            setErrors(errs => ({...errs, "name": true}));
            return;
        } else {
            setErrors(errs => ({...errs, "name": false}));
        }

        CreateBrand(inputs);
    };

    const handleNewClick = () => {
        let empty = {};
        for (const input in inputs) {
            let value = "";
            if (input === 'longitude' ||input === 'latitude') {
                value = 0;
            }
            empty[input] = value;
        }
        setInputs(empty);
        setSelectedId("");
    };

    const inputStyle = {
        marginBottom: 10
    };

    const onPropertyValueChange = (event) => {
        setInputs({...inputs, [event.target.id]: event.target.value.toUpperCase()});

    };

    const renderBrands = () => {
        return (
            <Table bordered hover>
                <thead>
                <tr className="text-center" bgcolor={Colors.greyhish}>
                    <th>{t('name')}</th>
                </tr>
                </thead>
                <tbody>
                {brands.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1).map((x) =>
                    <tr key={x.id} bgcolor={x.id === selectedId ? Colors.defaultBackground : Colors.white}
                        onClick={() => {
                            setSelectedId(x.id)
                        }} style={{cursor: 'pointer'}}>
                        <td>{x.name}</td>
                    </tr>)}
                </tbody>
            </Table>
        )
    };

    return (
        <main>
            <div className="container w-50">
            <span className="space"/>
            <div className="justify-content-center">
                <Form.Group as={Row}>
                    <Form.Label column sm={3}> {t('name')}   </Form.Label>
                    <Col sm={9}><FormControl id="name" type="text" placeholder={t('name')} className="mr-sm-2"
                                             style={inputStyle} isInvalid={errors.name}
                                             onChange={(event) => onPropertyValueChange(event)}
                                             value={inputs.name}
                    />
                    </Col>
                </Form.Group>

                <div className="text-center">

                    <Button variant="outline-dark" style={{marginRight: 10, backgroundColor: Colors.greyhish}}
                            onClick={() => {
                                handleNewClick()
                            }}>{t('new')}</Button>

                    <Button variant="outline-dark" onClick={() => {
                        handleClick()
                    }} disabled={!inputs.name}> {processing ?
                        <Spinner as="span" animation="grow" size="sm" role="status"
                                 aria-hidden="true"/> : null}{t('validate')}</Button>
                </div>
            </div>
            <span className="space"/>
            <div>{renderBrands()}</div>
            </div>
        </main>
    );
};

export default BrandScreen
