import React, {useEffect, useState} from "react";
import {Alert} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';


const Alerts = ({show, type, heading, message, onClose}) => {
    const [display, setDisplay] = useState(show);

    useEffect(() => {
        setDisplay(show);
    }, [show]);

    return (
        <Alert variant={type} show={display} onClose={() => {setDisplay(false); onClose()}} dismissible style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
        }}>
            <Alert.Heading>{heading}</Alert.Heading>
            <p>
                {message}
            </p>
        </Alert>
    )
};

const AlertMessage = (message, id, type) => {
    return ({
            show: true,
            message: message,
            heading: "",
            type: type,
            id: id,
        }
    )
};

const ErrorAlert = (message) => {
    return ({
            show: true,
            message: message,
            heading: "",
            type: "danger",
            id: Math.random(),
        }
    )
};

export {Alerts, ErrorAlert, AlertMessage};
