import React, { useEffect, useState } from 'react';
import { Table } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//hooks

import { useTranslation } from "react-i18next";
import 'react-awesome-slider/dist/styles.css';
import {GetAllStores } from '../queries/validations';
import { FormatAddress } from '../utils/utils';


const StoreListScreen = () => {
    const { t } = useTranslation();
    const [message, setMessage] = useState();

    const [relayPoints, setRelayPoints] = useState([])

    useEffect(() => {
        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = async () => {
        try {
            const cs = await GetAllStores();

            setRelayPoints(cs)
        } catch (error) {
            setMessage(error.response.data.message)
        }
    }

  
    const displayAnnounces = () => (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>{'Nom'}</th>
                    <th>{'Localisation'}</th>
                    <th>{'Tel'}</th>
                    <th>{'WhatsApp'}</th>
                    <th>{'Propriétaire'}</th>
                    <th>{'Tel Pro'}</th>
                    <th>{'Mode'}</th>
                </tr>
            </thead>
            <tbody>
                {relayPoints.map((x) =>
                    <tr key={x.id}>
                        <td>{x.name}</td>
                        <td>{FormatAddress(x.address)}</td>
                        <td>{x.phoneNumber}</td>
                        <td>{x.whatsappNumber}</td>
                        <td>{x.responsibleName}</td>
                        <td>{x.responsiblePhoneNumber}</td>
                        <td>{x.fashions.join()}</td>
                    </tr>)}
            </tbody>
        </Table>

    );
  

    return (
            <Row className="text-center">
                <Col sm={12}>
                    {displayAnnounces()}
                </Col>

            </Row>
    );
};

export default StoreListScreen
