import React from "react";
import {Col, Row} from "react-bootstrap";

//services
import Colors from "../utils/Colors";
import FONTS from "../utils/fonts";

const Header = ({userName, pageName}) => {
    return (
        <Row style={styles.container}>
            <Col xs={9} md={10} xl={10} style={styles.bar}
                 className="d-flex align-items-center justify-content-between">
                <div className="d-flex  align-items-center pl-5">
                    Freepin
                </div>
                <div style={styles.name}>
                    {pageName.toUpperCase()}
                </div>

            </Col>
            <Col xs={3} md={2} xl={2} style={styles.logOutContainer}>
                {userName}
            </Col>
        </Row>

    )
};

const styles = {
    container: {height: 55,},
    logOutContainer: {
        backgroundColor: Colors.primary,
        margin: 0,
        padding: 0,
        fontSize: 11,
        color: Colors.white,
        fontFamily: FONTS.Regular,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    bar: {background: `linear-gradient(190deg, ${Colors.secondary}, ${Colors.primary})`},
    name: {
        color: Colors.white,
        fontFamily: FONTS.Regular,
    },
    logOut: {
        fontSize: 11,
        color: Colors.white,
        fontFamily: FONTS.Regular,
    }
};
export default Header;
