import React, {useEffect, useState} from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import {useSelector} from "react-redux";
import {Button, Col, Container, Row, Spinner} from "react-bootstrap";
import '../css/login.css'
//hooks
import useRematchDispatch from "../hooks/useRematchDispatch";
import {useTranslation} from "react-i18next";
//services
import FONTS from "../utils/fonts";
import Colors from "../utils/Colors";
//components
import ForgetPasswordModal from "../components/forgetPasswordModal";
import {AlertMessage, Alerts} from "../components/alert";

import logo from "../images/logo.jpg";
import TextField from "@material-ui/core/TextField";
import {AppStyles} from "../css/styles";
import Footer from "../components/Footer";
import Helmet from "react-helmet";


const EmailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const LoginScreen = (props) => {
    const {t} = useTranslation();
    const history = useHistory();
    const {ForgotPassword, PostLogin, GetLogos} = useRematchDispatch(dispatch => ({
        PostLogin: dispatch.user.PostLogin,
        ForgotPassword: dispatch.user.ForgotPassword,
        GetLogos: dispatch.customer.GetLogos,
    }));
    const error = useSelector(state => state.user.error);
    const redirect = useSelector(state => state.user.redirect);
    const onLoading = useSelector(state => state.user.ongoing);


    const [modalShow, setModalShow] = useState(false);
    const [alert, setAlert] = useState({
        show: false,
        message: "",
        heading: "",
        type: ""
    });
    const [errors, setErrors] = useState({
        email: false,
        password: false
    });

    const [inputs, setInputs] = useState({
        userName: "",
        password: "",
        isAdmin: true,
    });

    const {from} = props.location.state || {from: {pathname: "/"}};
    useEffect(() => {
        //GetLogos()
    }, []);

    useEffect(() => {
        if (error !== null) {
            setAlert(alert => AlertMessage(t(error.message), Date.now(), "danger"))
        }

    }, [error]);

    useEffect(() => {
        setAlert(al => ({...al, show: false}));
    }, []);

    const handleClick = () => {
        if (!EmailFormat.test(inputs.userName)) {
            setErrors(inputs => ({...inputs, "email": true}));
            return
        } else {
            setErrors(inputs => ({...inputs, "email": false}));
        }
        if (inputs.password.length < 4) {
            setErrors(inputs => ({...inputs, "password": true}));
            return;
        } else {
            setErrors(inputs => ({...inputs, "password": false}));
        }
        inputs.email = inputs.userName
        PostLogin(inputs);
    };

    const onPropertyValueChange = (event) => {
        setInputs({...inputs, [event.target.id]: event.target.value});
    };

    const onForgotPasswordReturn = (value) => {
        setModalShow(false);
        if (value && value !== "") {
            ForgotPassword(value)
        }

    };

    return (
        <>
            {redirect ? <Redirect to={from}/> :
                <div>
                    <Helmet>
                        <title>{t('login_title')}</title>
                    </Helmet>
                    <Container fluid style={styles.root}>
                        <Row style={styles.middle}>
                            <Col className="d-none d-md-block" md={6} xl={8}>
                                <Row className="land-h">
                                    <Col className="my-auto">
                                        <div style={styles.image}>

                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                            <Col xs={12} md={6} xl={4} className="loginContainer p-2 p-xl-5 h-100 scrollable"
                                 style={styles.container}>
                                <Row>
                                    <Col sx={12} className="mb-5 mt-5 land-content" style={styles.image}>
                                        <img src={logo} alt={'Easy Meeting'} width="30%" className="land-image"/>
                                    </Col>
                                    <Col xs={12}>
                                        <TextField type="text" placeholder={t('email')} id="userName" error={errors.email}
                                                   onChange={(event) => onPropertyValueChange(event)}
                                                   style={AppStyles.loginInput} size="medium" className="w-100 land-content"
                                                   InputProps={AppStyles.input} InputLabelProps={AppStyles.input}/>
                                    </Col>
                                    <Col xs={12} className="mt-3 land-mt">
                                        <TextField type="password" placeholder={t('password')} id="password" error={errors.password}
                                                   onChange={(event) => onPropertyValueChange(event)}
                                                   style={AppStyles.loginInput} size="medium" className="w-100 land-content"
                                                   InputProps={AppStyles.input} InputLabelProps={AppStyles.input}/>
                                    </Col>
                                    <Col xs={12} className="mt-3 land-mt">
                                        <div className="float-right" style={AppStyles.loginInput}>
                                        <span className="btn btn-link txt1" style={styles.passTitle}
                                              onClick={() => setModalShow(true)}>{t('forgot_password_text')}</span>
                                        </div>

                                    </Col>
                                    <Col xs={12}>
                                        <div className="mt-2" style={AppStyles.loginInput}>
                                            <Button onClick={() => {
                                                handleClick()
                                            }} className="w-100"
                                                    style={{backgroundColor: Colors.primary, color: Colors.white}}> {onLoading ?
                                                <Spinner as="span" animation="grow" size="sm" role="status"
                                                         aria-hidden="true"/> : null} {t('login')}</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Footer/>

                        <ForgetPasswordModal show={modalShow} onHide={(value) => onForgotPasswordReturn(value)}/>
                        <Alerts show={alert.show} type={alert.type} message={alert.message} heading={alert.heading}
                                onClose={() => {
                                }}/>
                    </Container></div>}
        </>
    );
};

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        backgroundColor: Colors.white,
    },
    middle: {
        backgroundColor: Colors.primary,
        height: '95%',
    },
    image: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '100%',
    },
    container: {
        backgroundColor: Colors.white,

    },
    passTitle: {
        color: '#636770',
        fontFamily: FONTS.Regular,
        fontSize: 11,
    },
};

export default LoginScreen
