import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {Button, Col, Row, Spinner} from "react-bootstrap";

import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";

//hooks
import useRematchDispatch from "../../hooks/useRematchDispatch";
import {useTranslation} from "react-i18next";
//components
import {AppStyles} from "../../css/styles";

import {Alerts} from "../../components/alert";

const EmailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const Content = ({user}) => {
    const {t} = useTranslation();

    const {CreateUser} = useRematchDispatch(dispatch => ({
        CreateUser: dispatch.user.Create,
    }));

    const ongoing = useSelector(state => state.user.ongoing);
    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        userName: false,
        password: false,
    });
    const [inputs, setInputs] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        userName: "",
        password: "",
        isAdmin: false
    });

    const [alert, setAlert] = useState({
        id: Date.now(),
        show: false,
        message: "",
        heading: "",
        type: ""
    });

    useEffect(() => {
        if (user) {
            setInputs(user)
        }
    }, [user]);


    const onPropertyValueChange = (event) => {
        setInputs({...inputs, [event.target.id]: event.target.value});
    };
    const handleClick = () => {
        if (inputs.firstName.length < 2) {
            setErrors(previous => ({...previous, "firstName": true}));
            return;
        } else {
            setErrors(previous => ({...previous, "firstName": false}));
        }
        if (inputs.lastName.length < 2) {
            setErrors(previous => ({...previous, "lastName": true}));
            return;
        } else {
            setErrors(previous => ({...previous, "lastName": false}));
        }
        if (!EmailFormat.test(inputs.userName)) {
            setErrors(previous => ({...previous, "userName": true}));
            return
        } else {
            setErrors(previous => ({...previous, "userName": false}));
        }
        if (inputs.password.length < 4) {
            setErrors(previous => ({...previous, "password": true}));
            return;
        } else {
            setErrors(previous => ({...previous, "password": false}));
        }
        inputs.email = inputs.userName;
        CreateUser(inputs);
    };


    const handleNewClick = () => {
        if (user) return;
        let empty = {};
        for (const input in inputs) {
            empty[input] = "";
        }
        setInputs(empty);
    };


    return (
        <main className={user && "editZone"}>
            <Row className="text-center mt-2">
                <Col xs={12} md={12} xl={1}/>
                <Col xs={12} md={4} xl={2}>
                    <TextField type="text"
                               label={t('first_name')}
                               id="firstName"
                               error={errors.firstName} required disabled={user}
                               onChange={onPropertyValueChange}
                               value={inputs.firstName} size="medium" className="w-100" InputProps={AppStyles.input}
                               InputLabelProps={AppStyles.input}/>
                </Col>
                <Col xs={12} md={4} xl={2}>
                    <TextField type="text"
                               label={t('last_name')}
                               id="lastName"
                               error={errors.lastName} required disabled={user}
                               onChange={onPropertyValueChange}
                               value={inputs.lastName} size="medium" className="w-100" InputProps={AppStyles.input}
                               InputLabelProps={AppStyles.input}/>
                </Col>
                <Col xs={12} md={4} xl={2}>
                    <TextField type="text"
                               label={t('phone_number')} disabled={user}
                               id="phoneNumber"
                               onChange={onPropertyValueChange}
                               value={inputs.phoneNumber} size="medium" className="w-100" InputProps={AppStyles.input}
                               InputLabelProps={AppStyles.input}/>
                </Col>
                <Col xs={12} md={6} xl={2}>
                    <TextField type="text"
                               label={t('email')}
                               id="userName" error={errors.userName} required disabled={user}
                               onChange={onPropertyValueChange}
                               value={inputs.userName} size="medium" className="w-100" InputProps={AppStyles.input}
                               InputLabelProps={AppStyles.input}/>
                </Col>
                <Col xs={12} md={6} xl={2}>
                    <TextField type="password"
                               label={t('password')} id="password" required
                               error={errors.password}
                               onChange={onPropertyValueChange}
                               value={inputs.password} size="medium" className="w-100" InputProps={AppStyles.input}
                               InputLabelProps={AppStyles.input}/>
                </Col>

                <Col xs={12} md={12} xl={1}/>
            </Row>
            <Row className="mt-4 mb-4">
                <Col xs={12} md={2}>
                    <FormControlLabel
                        control={<Checkbox id="isAdmin" checked={inputs.isAdmin} onChange={(e) => setInputs({...inputs, isAdmin: e.target.checked})}/>}
                        label="admin ?"/>
                </Col>
                <Col xs={8} md={3}>
                    <Button className="w-100" onClick={() => {
                        handleClick()
                    }} style={AppStyles.validationButton}>
                        {ongoing ?
                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"/> : null}
                        {user ? t('button.save_modification') : t('button.save_user')}
                    </Button>
                </Col>
                <Col xs={4} md={3}>
                    <Button variant="outline-dark" style={AppStyles.cancelButton}
                            onClick={() => {
                                handleNewClick()
                            }}>{t('cancel')}</Button>
                </Col>
                <Col xs={12} md={4}/>
            </Row>
            <Alerts key={alert.id} show={alert.show} type={alert.type} message={alert.message} heading={alert.heading}
                    onClose={() => {
                    }}/>
        </main>

    );
};

Content.propTypes = {
    user: PropTypes.object,
};


export default Content;
