import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Accordion, Col,  Row } from "react-bootstrap";
import {UserPlus} from "react-feather";
import {AddCircle, Edit, Visibility} from "@material-ui/icons";

//services
import {useTranslation} from "react-i18next";
import useRematchDispatch from "../../hooks/useRematchDispatch";
import Colors from "../../utils/Colors";
import {AppStyles} from "../../css/styles";

//components
import Content from "./content";


import Grant from "./grant";


const UserScreen = () => {
    const {t} = useTranslation();
    const {GetUsers} = useRematchDispatch(dispatch => ({
        GetUsers: dispatch.user.GetUsers,
    }));

    const users = useSelector(state => state.user.users);
    const [userId, setUserId] = useState();

    useEffect(() => {
        GetUsers();
    }, []);

    const handleChangeClick = (id) =>{
        setUserId(id === userId ? '' : id)
    };

    const renderUser = (user) => {
        const iconStyle = userId === user.id ? AppStyles.ActiveIcon : AppStyles.normalIcon;
        const textStyle = userId === user.id ? AppStyles.modifyButtonText : AppStyles.greyBarText;

        return (
            <Row className="shadow mb-3" key={user.id}>
                <Col>
                    <Row className="align-items-center justify-content-center align-self-center"
                         style={AppStyles.accordionHeader}>
                        <Col xs={12} md={1}/>
                        <Col xs={4} md={4}>
                            {`${user.firstName}  ${user.lastName}`}
                        </Col>
                        <Col xs={2} md={2}>
                            {user.phoneNumber}
                        </Col>
                        <Col xs={2} md={2} className="d-flex flex-row-reverse">
                            <Accordion.Toggle eventKey={user.id}>
                                <div onClick={() => handleChangeClick(user.id)} style={AppStyles.modifyButton}>
                                    <Edit style={iconStyle}/>
                                    <span style={textStyle} className="ml-2"> {t('modify')}</span>
                                </div>
                            </Accordion.Toggle>
                        </Col>
                    </Row>
                    <Accordion.Collapse eventKey={user.id}>
                        {userId === user.id ? (
                            <Grant user={user} />
                        ) : <div/>}
                    </Accordion.Collapse>
                </Col>
            </Row>
        )
    };


    return (
            <>
                <Row className="greyBar">
                    <Col className="d-flex align-items-center">
                        <AddCircle style={{color: Colors.black}}/> <span style={AppStyles.greyBarText}
                                                                         className="ml-2"> {t('labels.menu_create_user')}</span>
                    </Col>
                </Row>
                <Content/>
                <Row className="greyBar">
                    <Col className="d-flex align-items-center">
                        <Visibility style={{color: Colors.black}}/> <span style={AppStyles.greyBarText}
                                                                          className="ml-2"> {t('labels.view_users')}</span>
                    </Col>
                </Row>
                <Accordion>
                    {users.map(s => renderUser(s))}
                </Accordion>
            </>
    );
};

export default UserScreen
