export const GenerateError = (err) => {
    let message;
    if (!err.response || !err.response.data || !err.response.data.error)
    {
        message = "errors.network";
    } else {
        message = "errors." + err.response.data.error.code;
        //message = "errors.custom";//err.response.data.error.message;
    }
    return {
        id: Date.now()+1,
        message
    }
};
