import axios from './axios'

const BASE = "/customer";

export const BindServiceToOffice = async (data) => {
    return axios.post(BASE+"/service-to-office", data);
};

export const Create = async (data) => {
    return axios.post(BASE+"/create", data)
};

export const Update = async (data) => {
    axios.interceptors.request.use(config => {
        config.headers['content-type'] = 'multipart/form-data';
        return config;
    }, error => {
        return Promise.reject(error);
    });
    return axios.put(BASE+"/update", data)
};

export const UpSertOffice = async (data) => {
    return axios.post(BASE+"/office", data);
};

export const UpSertService = async (data) => {
    return axios.post(BASE+"/service", data);
};

export const GetCustomer = async (customerId) => {
    return axios.get(BASE + "/", {
        params: {
            customerId
        }
    });
};

export const OfficesList = async (customerId) => {
    return axios.get(BASE + "/offices", {
        params: {
            customerId
        }
    });
};

export const OfficeServicesList = async (officeId) => {
    return axios.get(BASE + "/office-services", {
        params: {
            officeId
        }
    });
};

export const ServicesList = async (customerId) => {
    return axios.get(BASE + "/services", {
        params: {
            customerId
        }
    });
};

export const GetOfficeStatistics = async (customerId, startDate, endDate) => {
    return axios.get(BASE + "/statistics", {
        params: {
            customerId,
            startDate,
            endDate
        }
    });
};

export const GetLogos = async () => {
    return axios.get(BASE + "/logos");
};






