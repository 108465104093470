import axios from './axios'

export const CarrierToValidate = async () => {
    try {
        const res = await  axios.get("delivery/carriers/to-validate");
        return res.data;
    } catch (error) {
        throw error
    }
   
};


export const ValidateCarrier = async (data) => {
   try {
    await  axios.post(`/delivery/carrier/validation`, data)
   } catch (error) {
    throw error
   }
    
};

export const RelayPointToValidate = async () => {
    try {
        const res = await  axios.get("delivery/points/to-validate");
        return res.data;
    } catch (error) {
        throw error
    }
   
};

export const ValidateRelayPoint = async (data) => {
    try {
     await  axios.post(`/delivery/point/validation`, data)
    } catch (error) {
     throw error
    }
     
 };

 export const GetProductsToValidate = async () => {
    try {
        const res = await  axios.get("products/to-validate");
        return res.data;
    } catch (error) {
        throw error
    }
   
};

export const ValidateProduct = async (data) => {
    try {
     await  axios.post(`/product/validation`, data)
    } catch (error) {
     throw error
    }
     
 };

 export const GetItemsToValidate = async () => {
    try {
        const res = await  axios.get("items/to-validate");
        return res.data;
    } catch (error) {
        throw error
    }
   
};

export const ValidateItem = async (data) => {
    try {
     await  axios.post(`/item/validation`, data)
    } catch (error) {
     throw error
    }
     
 };

 export const GetAllRelayPoints = async () => {
    try {
        const res = await  axios.get("delivery/all-points");
        return res.data;
    } catch (error) {
        throw error
    }
};

export const GetAllCarriers = async () => {
    try {
        const res = await  axios.get("delivery/all-carriers");
        return res.data;
    } catch (error) {
        throw error
    }
};

export const GetAllStores = async () => {
    try {
        const res = await axios.get("stores");
        return res.data;
    } catch (error) {
        throw error
    }
};