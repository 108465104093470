import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import LoginScreen from "../screens/loginScreen";
import MainScreen from "../screens/mainScreen";

export const  ApplicationRoutes = () => (
    <Router>
            <PrivateRoute exact path='/' component={MainScreen} />
            <Route path="/login" component={LoginScreen} />
    </Router>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
);
