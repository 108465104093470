import React from "react";
import {Button, Container, FormControl, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";


const ForgetPasswordModal = (props) => {
    const {t} = useTranslation();
    let userEmail = "";

    const onHandleChange = (value) => {
        userEmail = value;
    };

    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('forgot_password_text')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {t('forgot_password_modal_text')}

                    <FormControl className="mr-sm-2"  type="text" style={{marginTop: 20}}
                                 placeholder={t('email')} id="email" onChange={(event) => {
                        onHandleChange(event.target.value)
                    }}/>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {props.onHide(userEmail)}}>{t('sent')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ForgetPasswordModal;
