import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {Col, Row} from "react-bootstrap";
import {AddCircle, Delete} from "@material-ui/icons";
import {Button, MenuItem, TextField} from "@material-ui/core"

//services
import useRematchDispatch from "../../hooks/useRematchDispatch";
import {useTranslation} from "react-i18next";
import {AppStyles} from "../../css/styles";
import FONTS from "../../utils/fonts";
import Colors from "../../utils/Colors";
//components

import {CancelButton, ValidationButton} from "../../components/Buttons";


const Grant = ({user}) => {
    const {t} = useTranslation();

    const Roles = [
        {code: "AD", name: "ADMINISTRATOR"},
        {code: "PA", name: "PARTNER"},
    ];

    const {GetPrivileges, GrantUser} = useRematchDispatch(dispatch => ({
        GetPrivileges: dispatch.user.GetUserPrivileges,
        GrantUser: dispatch.user.GrantUser,
    }));

    const ongoing = useSelector(state => state.user.ongoing);
    const userPrivileges = useSelector(state => state.user.privileges);
    const [pv, setPv] = useState([]);
    const [roleCode, setRoleCode] = useState("");

    useEffect(() => {
        if (user) {
            GetPrivileges(user.id);
        }
    }, [user]);

    useEffect(() => {
        setPv(userPrivileges)
    }, [userPrivileges]);


    const handleAddClick = () => {
        setPv([...pv, {roleCode}])
    };


    const handleSubmitClick = () => {
        if (pv.length > 0) {
            GrantUser({userId: user.id, Privileges: pv});
        }

    };

    const handleResetClick = () => {
        setPv(userPrivileges);
        setRoleCode("");
    };

    const handleDeleteClick = (index) => {
        const newValues = pv;
        newValues.splice(index, 1);
        setPv([...newValues]);
    };

    const privilegesContainer = () => {
        return (
            <>
                {pv.map((p, i) =>
                    <Row className="text-center editZone p-2" key={i}>
                        <Col xs={3} md={3}/>

                        <Col xs={3} md={3}>
                            <TextField  select label={t('role')} value={p.roleCode} disabled
                                       size="medium" className="w-100" InputProps={AppStyles.input} InputLabelProps={AppStyles.input}
                                       error={false}>

                                {Roles.map((x) => (
                                    <MenuItem key={x.code} value={x.code} style={{fontSize: 10}}>
                                        {x.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Col>
                        <Col xs={3} md={2}>
                            <Button startIcon={<Delete/>}  size="small" className="loweCase mt-2"
                                    style={styles.deleteButton}  onClick={() => {
                                handleDeleteClick(i)
                            }}>
                            </Button>
                        </Col>
                    </Row>
                )}
            </>
        )
    };

    const newContainer = () => {
        return (
                    <Row className="text-center p-2" >
                        <Col xs={3} md={3}/>
                        <Col xs={3} md={3}>
                            <TextField id="roleCode" select label={t('role')} value={roleCode} required error={false}
                                        size="medium" className="w-100" InputProps={AppStyles.input} InputLabelProps={AppStyles.input}
                                       onChange={(event) => setRoleCode(event.target.value)}
                                        >
                                {Roles.map((x) => (
                                    <MenuItem key={x.code} value={x.code} style={{fontSize: 10}}>
                                        {x.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Col>
                        <Col xs={3} md={2}>
                            <Button startIcon={<AddCircle/>} size="small" className="loweCase mt-2"
                                    style={styles.addButton} disabled={!roleCode} onClick={() => {
                                handleAddClick()
                            }}>
                            </Button>
                        </Col>
                    </Row>
        )
    };


    return (
        <main >
            {privilegesContainer()}
            {newContainer()}
            <Row className="mt-4 mb-4">
                <Col xs={12} md={3}/>
                <Col xs={8} md={3}>
                    <ValidationButton onClick={handleSubmitClick} enabled={pv.length > 0}  label={t('button.save_modification')} onLoad={ongoing}/>
                </Col>
                <Col xs={4} md={3}>
                    <CancelButton onClick={handleResetClick}/>
                </Col>
                <Col xs={12} md={4}/>
            </Row>
        </main>

    );
};

Grant.propTypes = {
    user: PropTypes.object,
    services: PropTypes.array,
    offices: PropTypes.array,
};


Grant.defaultProps = {
    locations: [],
    services: [],
};

const styles = {
    addButton: {
        backgroundColor: Colors.black,
        color: Colors.white,
        fontSize: 12,
        fontFamily: FONTS.Regular
    },
    deleteButton: {
        backgroundColor: Colors.grey,
        color: Colors.white,
        fontSize: 10,
        fontFamily: FONTS.Regular
    }
};



export default Grant;
