import {createModel} from "@rematch/core";
import { GenerateError } from "../utils/errors";
import { LoadCorporateInformation } from "../queries/main";

export const MainModel = () =>
    createModel({
        state: {
            error: null,
            success: null,
            processing: false,
            corporateInfo: {}
        },
        reducers: {
            errors(state, err) {
                return {...state, error: err}
            },
            success(state, id) {
                return {...state, success: id}
            },
            updateProcessing(state, p) {
                return {...state, processing: p}
            },
            updateCorporateInfo(state, data) {
                return {state, corporateInfo: data}
            }

        },
        effects: dispatch => ({
            SetError(err) {
                this.updateProcessing(false);
                this.errors(GenerateError(err));
            },
            SetSuccess() {
                this.updateProcessing(false);
                this.success(Date.now());
            },
            SetProcessing(state) {
                this.updateProcessing(state);
            },

            async GetDayCorporate(state) {
                try {
                    const now = new Date(new Date().setUTCHours(0,0,0,0)).toISOString()
                    const data = await LoadCorporateInformation({start: now, end: now})
                    
                    // this.updateCorporateInfo(data);
                } catch (err) {
                    dispatch.main.SetError(err);
                }
            },
            
        })
    });

