import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Button, Container, FormControl, Modal, Spinner, Table } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//hooks

import { useTranslation } from "react-i18next";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import {RelayPointToValidate, ValidateRelayPoint } from '../../queries/validations';
import { FormatAddress } from '../../utils/utils';


const RelayPointValidationScreen = () => {
    const { t } = useTranslation();

    const ongoing = useSelector(state => state.validations.ongoing);


    const [announceId, setAnnounceId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState();

    const [relayPoints, setRelayPoints] = useState([])

    useEffect(() => {
        loadRelayToints()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadRelayToints = async () => {
        try {
            const cs = await RelayPointToValidate()
            console.log(cs)
            setRelayPoints(cs)

        } catch (error) {
            setMessage(error.response.data.message)
        }
    }

    const onValidate = async (id) => {
        const data = { id, valid: true };
        try {
            await ValidateRelayPoint(data);
            await loadRelayToints();
        } catch (error) {
            console.log(error)
        }

    };

    const onDeny = (id) => {
        setShowModal(true);
        setAnnounceId(id);
    };

    const onModalSubmit = async () => {
        setShowModal(false);
        if (!message || message.length === 0) {
            return
        }
        const data = { id: announceId, valid: false, comment: message };
        try {
            await ValidateRelayPoint(data);
            await loadRelayToints();
        } catch (error) {
            console.log(error)
        }

    };

    const displayImage = (images) => {
        return (

            <AwesomeSlider style={{ height: 250, width: 300 }} bullets={false}>
                {images.map(x => <div key={Date.now()} data-src={x} />)}
            </AwesomeSlider>)
    };


    const displayAnnounces = () => (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>{'Nom'}</th>
                    <th>{'Localisation'}</th>
                    <th>{'Tel'}</th>
                    <th>{'WhatsApp'}</th>
                    <th>{'Propriétaire'}</th>
                    <th>{'Activité'}</th>
                    <th>{'Message'}</th>
                    <th colSpan={2}>{t('Actions')}</th>
                </tr>
            </thead>
            <tbody>
                {relayPoints.map((x) =>
                    <tr key={x.id}>
                        <td>{x.name}</td>
                        <td>{FormatAddress(x.address)}</td>
                        <td>{x.phoneNumber}</td>
                        <td>{x.whatsappNumber}</td>
                        <td>{x.responsibleName}</td>
                        <td>{x.responsiblePhoneNumber}</td>
                        <td>{x.validationComment}</td>
                        <td><Button variant="outline-success" onClick={() => onValidate(x.id)}> {ongoing ?
                            <Spinner as="span" animation="grow" size="sm" role="status"
                                aria-hidden="true" /> : null}{t('validate')}</Button></td>
                        <td><Button variant="danger" onClick={() => onDeny(x.id)}> {ongoing ?
                            <Spinner as="span" animation="grow" size="sm" role="status"
                                aria-hidden="true" /> : null}{t('deny')}</Button></td>
                    </tr>)}
            </tbody>
        </Table>

    );
    const displayModal = () => (

        <Modal show={showModal} onHide={() => setShowModal(false)} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('deny_text')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <FormControl as="textarea" className="mr-sm-2" type="text" style={{ marginTop: 20 }}
                        id="msg" onChange={(event) => {
                            setMessage(event.target.value)
                        }} />
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => { onModalSubmit() }}>{t('sent')}</Button>
            </Modal.Footer>
        </Modal>
    );


    return (
            <Row className="text-center">
                <Col sm={12}>
                    {displayAnnounces()}
                    {displayModal()}
                </Col>

            </Row>
    );
};

export default RelayPointValidationScreen
