import React from 'react';
import {Button, Spinner} from "react-bootstrap";
import {AppStyles} from "../css/styles";
import {useTranslation} from "react-i18next";

export const CancelButton = ({onClick}) => {
    const {t} = useTranslation();
    return (
        <Button className="loweCase" onClick={onClick} variant="outline-dark"
                style={AppStyles.cancelButton}>{t('cancel')}</Button>
    );
};


export const ValidationButton = ({onClick, label, onLoad, enabled}) => {
    return (
        <Button className="w-100 loweCase" onClick={onClick} style={AppStyles.validationButton} disabled={!enabled}>
            {onLoad ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"/> : null}
            {label}
        </Button>
    );
};
