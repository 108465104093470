import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Button, Col, Row, Spinner} from "react-bootstrap";
//hooks
import {useTranslation} from "react-i18next";
import useRematchDispatch from "../../hooks/useRematchDispatch";
import {Lock} from "react-feather";
import Colors from "../../utils/Colors";
import {AppStyles} from "../../css/styles";
import TextField from "@material-ui/core/TextField";


const ChangePasswordScreen = () => {
    const {t} = useTranslation();
    const {ChangePassword} = useRematchDispatch(dispatch => ({
        ChangePassword: dispatch.user.ChangePassword,
    }));
    const ongoing = useSelector(state => state.user.ongoing);
    const [inputs, setInputs] = useState({
        Password: '',
        newPassword: '',
        confirmation: ''
    });

    const [errors, setErrors] = useState({
        old: false,
        new: false,
    });

    const onPropertyValueChange = (event) => {
        setInputs({...inputs, [event.target.id]: event.target.value});
    };

    const handleClick = () => {
        if (inputs.Password.length < 4) {
            setErrors(errs => ({...errs, "old": true}));
            return;
        } else {
            setErrors(errs => ({...errs, "old": false}));
        }
        if (inputs.newPassword.length < 4 || inputs.newPassword !== inputs.confirmation) {
            setErrors(errs => ({...errs, "new": true}));
            return;
        } else {
            setErrors(errs => ({...errs, "new": false}));
        }
        ChangePassword(inputs);
    };

    const inputStyle = {
        marginBottom: 10
    };
    return (
        <>
            <Row className="greyBar mt-4">
                <Col className="d-flex align-items-center">
                    <Lock style={{color: Colors.black}} size={14}/> <span style={AppStyles.greyBarText}
                                                                className="ml-2"> {t('labels.menu_profile_change_password')}</span>
                </Col>
            </Row>
            <Row className="text-center">
                <Col xs={12} md={4}>
                    <TextField id="Password" type="password" label={t('old_password')} error={errors.old}
                               onChange={(event) => onPropertyValueChange(event)}
                               size="medium" className="w-100"
                               InputProps={AppStyles.input} InputLabelProps={AppStyles.input}/>
                </Col>
                <Col xs={12} md={4}>
                    <TextField id="newPassword" type="password" label={t('new_password')} error={errors.new}
                               onChange={(event) => onPropertyValueChange(event)}
                               size="medium" className="w-100"
                               InputProps={AppStyles.input} InputLabelProps={AppStyles.input}/>
                </Col>
                <Col xs={12} md={4}>
                    <TextField id="confirmation" type="password" label={t('confirmation')} error={errors.new}
                               onChange={(event) => onPropertyValueChange(event)}
                               size="medium" className="w-100"
                               InputProps={AppStyles.input} InputLabelProps={AppStyles.input}/>
                </Col>

            </Row>
            <Row className="mt-4 mb-4">
                <Col xs={12} md={2}/>
                <Col xs={12} md={3}>
                    <Button className="w-100" onClick={() => {handleClick()}} style={AppStyles.validationButton}>
                        {t('validate_pass')}
                    </Button>
                </Col>
                <Col xs={12} md={4}/>
            </Row>
        </>
    );
};

export default ChangePasswordScreen;
