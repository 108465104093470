import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {Button, Spinner, Row, Col, FormControl} from "react-bootstrap";

import {MenuItem, TextField} from "@material-ui/core";


//hooks
import useRematchDispatch from "../../hooks/useRematchDispatch";
import {useTranslation} from "react-i18next";
//components
import {AppStyles} from "../../css/styles";

import {Edit, Lock} from "react-feather";
import Colors from "../../utils/Colors";

const EmailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const Update = () => {
    const {t} = useTranslation();

    const {GetUser, UpdateUser} = useRematchDispatch(dispatch => ({
        UpdateUser: dispatch.user.Update,
        GetUser: dispatch.user.Get,
    }));

    const ongoing = useSelector(state => state.user.ongoing);
    const user = useSelector(state => state.user.user);
    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        userName: false,
    });
    const [inputs, setInputs] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        userName: "",
    });

    useEffect(() => {
        GetUser()
    }, []);

    useEffect(() => {
        if (user) {
            setInputs(user)
        }
    }, [user]);

    const onPropertyValueChange = (event) => {
        setInputs({...inputs, [event.target.id]: event.target.value});
    };
    const handleClick = () => {
        if (inputs.firstName.length < 2) {
            setErrors(previous => ({...previous, "firstName": true}));
            return;
        } else {
            setErrors(previous => ({...previous, "firstName": false}));
        }
        if (inputs.lastName.length < 2) {
            setErrors(previous => ({...previous, "lastName": true}));
            return;
        } else {
            setErrors(previous => ({...previous, "lastName": false}));
        }
        if (!EmailFormat.test(inputs.userName)) {
            setErrors(previous => ({...previous, "userName": true}));
            return
        } else {
            setErrors(previous => ({...previous, "userName": false}));
        }
        UpdateUser(inputs);
    };


    const handleNewClick = () => {
        setInputs(user);
    };


    return (
        <main>
            <Row className="greyBar mt-4">
                <Col className="d-flex align-items-center">
                    <Edit style={{color: Colors.black}} size={14}/> <span style={AppStyles.greyBarText}
                                                                className="ml-2"> {t('labels.update_user')}</span>
                </Col>
            </Row>
            <Row className="text-center mt-2">
                <Col xs={12} md={12} xl={1}/>
                <Col xs={12} md={4} xl={2}>
                    <TextField type="text"
                               label={t('first_name')}
                               id="firstName"
                               error={errors.firstName} required
                               onChange={onPropertyValueChange}
                               value={inputs.firstName} size="medium" className="w-100" InputProps={AppStyles.input}
                               InputLabelProps={AppStyles.input}/>
                </Col>
                <Col xs={12} md={4} xl={2}>
                    <TextField type="text"
                               label={t('last_name')}
                               id="lastName"
                               error={errors.lastName}
                               onChange={onPropertyValueChange}
                               value={inputs.lastName} size="medium" className="w-100" InputProps={AppStyles.input}
                               InputLabelProps={AppStyles.input}/>
                </Col>
                <Col xs={12} md={4} xl={2}>
                    <TextField type="text"
                               label={t('phone_number')}
                               id="phoneNumber"
                               onChange={onPropertyValueChange}
                               value={inputs.phoneNumber} size="medium" className="w-100" InputProps={AppStyles.input}
                               InputLabelProps={AppStyles.input}/>
                </Col>
                <Col xs={12} md={6} xl={2}>
                    <TextField type="text"
                               label={t('email')}
                               id="userName" error={errors.userName} required
                               onChange={onPropertyValueChange}
                               value={inputs.userName} size="medium" className="w-100" InputProps={AppStyles.input}
                               InputLabelProps={AppStyles.input}/>
                </Col>


                <Col xs={12} md={12} xl={1}/>
            </Row>
            <Row className="mt-4 mb-4">
                <Col xs={12} md={2}/>
                <Col xs={8} md={3}>
                    <Button className="w-100" onClick={() => {handleClick()}} style={AppStyles.validationButton}>
                        {ongoing ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"/> : null}
                        {user ? t('button.save_modification') : t('button.save_user')}
                    </Button>
                </Col>
                <Col xs={4} md={3}>
                    <Button variant="outline-dark" style={AppStyles.cancelButton}
                            onClick={() => {
                                handleNewClick()
                            }}>{t('cancel')}</Button>
                </Col>
                <Col xs={12} md={4}/>
            </Row>

        </main>

    );
};


export default Update;
