import axios from './axios'

export const LoadCorporateInformation = async (dates) => {
    try {
       console.log(dates)
        const res = await  axios.post("admin/corporate", dates);
        
        return res.data;
    } catch (error) {
        throw error
    }
   
};
