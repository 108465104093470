import axios from './axios'

const BASE = "location";

export function FetchCountries() {
    return axios.get(BASE + "/countries");
}

export function FetchLocations() {
    return axios.get(BASE + "/locations-by-district");
}

export const FetchTowns = async (countryId) => {
    return axios.get(BASE + `/country/${countryId}/towns`);
};

export const FetchDistricts = async (townId) => {
    return axios.get(BASE + `/town/${townId}/districts`);
};


export const CreateCountry = async (data) => {
    return axios.post(BASE + '/country/add', data);
};

export const DeleteCountry = async (id) => {
    return axios.delete(`${BASE}/country/${id}`);
};

export const DeleteTown = async (id) => {
    return axios.delete(`${BASE}/town/${id}`);
};

export const DeleteDistrict = async (id) => {
    return axios.delete(`${BASE}/district/${id}`);
};

export const CreateTown = async (data) => {
    return axios.post(BASE + '/town', data);
};

export const CreateDistrict = async (data) => {
    return axios.post(BASE + '/district', data);
};

